<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';
import { TableSelect, SelectProduct } from './components';

formCreate.component('TableSelect', TableSelect);
formCreate.component('SelectProduct', SelectProduct);

export default {
  extends: Form,
  data() {
    return {
      rule: [],
      params: {},
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  async mounted() {
    this.rule = await this.getFormRule('terminal_orders_form');
    this.rule.map((v) => {
      const rowData = v;
      if (rowData.field === 'files') {
        rowData.props.showFileList = true;
        rowData.props.uploadType = 'file';
        rowData.props.listType = 'text';
        rowData.props.fileMsg = '只能上传pdf/word/excel/ppt/png/jpg文件';
        rowData.props.accept = '.pdf,.doc,.docx,.xlsx,.xls,.ppt,.pptx,.png,.jpg';
        if (this.formConfig.code === 'view') {
          rowData.props.disabled = true;
        }
      }
      if (rowData.field === 'transitDealerName') {
        const paramsObj = {
          functionCode: 'select_ds_list',
          data: [],
          selectionList: [],
          idKey: 'transitDealerCode',
          noReset: true,
          paramData: { enableStatus: '009' },
        };
        rowData.props = {
          ...rowData.props,
          paramsObj,
          placeholder: '大商名称',
        };
      }
      if (rowData.field === 'dealerName') {
        const paramsObj = {
          functionCode: 'select_distribut_list',
          data: [],
          selectionList: [],
          idKey: 'code',
          noReset: true,
          paramData: { enableStatus: '009' },
        };
        rowData.props = {
          ...rowData.props,
          paramsObj,
          placeholder: '分销商名称',
        };
      }
      if (rowData.field === 'detailedAddress') {
        const paramsObj = {
          functionCode: 'select_addr',
          data: [],
          selectionList: [],
          idKey: 'code',
          noReset: true,
          paramData: { enableStatus: '009' },
        };
        rowData.props = {
          ...rowData.props,
          paramsObj,
          placeholder: '收货地址',
        };
      }
      if (rowData.field === 'productInfo') {
        rowData.props = {
          functionCode: '',
          selection: [],
          idKey: 'id',
          notView: this.formConfig.code !== 'view',
          transitDealerCode: '',
        };
        rowData.value = [];
      }
      return rowData;
    });
    this.reload(this.rule);
    if (this.formConfig.selection[0]) {
      this.getData();
    }
  },
  methods: {
    // 表单渲染完成后调用
    formComplete() {
      const productInfo = this.getRule('productInfo');
      const transitDealerName = this.getRule('transitDealerName');
      transitDealerName.on.change = (val) => {
        this.setValue({
          transitDealerName: val.transitDealerName,
          transitDealerCode: val.transitDealerCode,
        });
        this.params.transitDealerCode = val.transitDealerCode;
        productInfo.props = {
          ...productInfo.props,
          transitDealerCode: val.transitDealerCode,
        };
      };
      const dealerName = this.getRule('dealerName');
      const detailedAddress = this.getRule('detailedAddress');
      dealerName.on.change = (val) => {
        this.setValue({
          dealerName: val.dealerName,
          dealerCode: val.dealerCode,
        });
        this.params.dealerCode = val.dealerCode;
        this.updateRules({
          detailedAddress: {
            props: {
              ...detailedAddress.props,
              paramsObj: {
                ...detailedAddress.props.paramsObj,
                paramData: { ...detailedAddress.props.paramsObj.paramData, dealerCode: val.dealerCode },
              },
            },
          },
        });
      };
      detailedAddress.on.change = (val) => {
        this.setValue({
          detailedAddress: val.detailedAddress,
          contactName: val.contactName,
          contactPhone: val.contactPhone,
        });
      };
    },
    // 获取表单数据
    getData() {
      request
        .get(`/dms/m/notice/findById/${this.formConfig.selection[0]}`, {
          id: this.formConfig.selection[0],
        })
        .then((res) => {
          res.result.customers.map((v) => {
            const rowData = v;
            rowData.customerCode = v.cusCode;
            rowData.customerName = v.cusName;
            return rowData;
          });
          res.result.nonCustomers.map((v) => {
            const rowData = v;
            rowData.customerCode = v.cusCode;
            rowData.customerName = v.cusName;
            return rowData;
          });
          const data = {
            ...res.result,
            type: `${res.result.type}`,
            productInfo: {
              orgs: res.result.orgs,
              nonOrgs: res.result.nonOrgs,
              customers: res.result.customers,
              nonCustomers: res.result.nonCustomers,
            },
          };
          this.setValue(data);
        });
    },
    // 提交
    async submit() {
      const params = {
        ...this.getFormData(),
        ...this.params,
      };
      console.log(params);
      if (!params.productInfo || !params.productInfo.length) return this.$message.error('请选择产品');
      if (!params.productInfo.some((item) => item.totalNum)) return this.$message.error('请输入产品数量');
      request.post('/dms/transitOrder/saveTransit', params).then((res) => {
        if (res.success) {
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
      return true;
    },
  },
};
</script>
