<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import AddForm from './form';

export default {
  extends: TablePage,
  components: {
    AddForm,
  },
  data() {
    return {
      formConfig: {},
      searchFormData: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  mounted() {
    this.getConfigList('terminal_orders_list');
  },
  methods: {
    modalClick({ val, row }) {
      console.log(val.code);
      if (val.code === 'add') {
        this.formName = 'AddForm';
        this.modalConfig.title = '新增';
        this.modalConfig.width = '80%';
        this.openDrawer();
      }
    },
  },
};
</script>
