var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-box" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 20, offset: 1 } },
            [
              _vm.notView
                ? _c("vxe-toolbar", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "buttons",
                          fn: function () {
                            return [
                              _c(
                                "vxe-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.addTable("")
                                    },
                                  },
                                },
                                [_vm._v("添加")]
                              ),
                              _c(
                                "vxe-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteTable("first")
                                    },
                                  },
                                },
                                [_vm._v("清空")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      860694970
                    ),
                  })
                : _vm._e(),
              _c(
                "vxe-table",
                {
                  attrs: { align: _vm.allAlign, data: _vm.tableData },
                  on: {
                    "checkbox-all": function (ref) {
                      var records = ref.records

                      return _vm.selectAllEvent("tableSelect", records)
                    },
                    "checkbox-change": function (ref) {
                      var records = ref.records

                      return _vm.selectChangeEvent("tableSelect", records)
                    },
                  },
                },
                [
                  _c("vxe-table-column", {
                    attrs: { type: "index", title: "行号", width: "50" },
                  }),
                  _c("vxe-table-column", {
                    attrs: { field: "productCode", title: "商品编码" },
                  }),
                  _c("vxe-table-column", {
                    attrs: { field: "productName", title: "商品名称" },
                  }),
                  _c("vxe-table-column", {
                    attrs: { field: "productPrice", title: "单价" },
                  }),
                  _c("vxe-table-column", {
                    attrs: { field: "spec", title: "规格" },
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      field: "totalNum",
                      title: "数量",
                      "cell-render": {
                        name: "$input",
                        props: { type: "integer" },
                        events: { blur: this.totalNumInput },
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }