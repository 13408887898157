<template>
  <div>
    <div @click="openSelect">
      <el-input readonly :disabled="disabled" :placeholder="placeholder" v-model="value"></el-input>
    </div>

    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>
<script>
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';

export default {
  components: {
    SelectConfig,
  },
  props: {
    value: String,
    disabled: Boolean,
    paramsObj: Object,
    placeholder: String,
  },
  methods: {
    // 打开弹窗
    openSelect() {
      if (this.disabled) {
        return;
      }
      const params = {
        ...this.paramsObj,
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    onGetSelect(value) {
      if (value.length > 0) {
        this.$emit('change', value[0]);
      }
    },
  },
};
</script>
